/* News & Events Section */
#event {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    padding: 50px 0;
    background-color: #6c6969;
  }
  
  #event .titleArea {
    display: table;
    left: -32px;
    margin: auto;
    position: absolute;
    top: 90px;
    transform: rotate(-270deg);
    width: auto;
  }
  
  #event .commonTittle {
    color: #FBC02D;
    font-size: 36px;
    letter-spacing: 10.8px;
    padding-bottom: 5px;
    text-transform: uppercase;
    word-wrap: break-word; /* Ensure long words break to the next line */
  }
  
  #event .subTitle {
    font-size: 12px;
    font-style: italic;
    text-transform: uppercase;
    letter-spacing: 3.6px;
    font-weight: 400;
    font-family: 'Merriweather', serif;
    padding-right: 12px;
    color: #999999;
  }
  
  .events-news {
    padding: 50px 0;
    background-color: #f9f9f9;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
  }
  
.events-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}
  
  .event-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    position: relative; /* Ensure the date can be positioned absolutely */
  }
  
  .event-card h3 {
    margin-top: 0;
    font-size: 1.5rem;
    color: #333;
  }
  
  .event-card p {
    margin: 10px 0;
    color: #777;
  }
  
  .event-card .event-description {
    flex-grow: 1; /* Allow the description to take up available space */
    margin: 10px 0; /* Add margin to ensure proper spacing */
  }
  
  .event-card .event-date {
    position: absolute;
    bottom: -10px;
    right: 10px;
    font-size: 0.9rem;
    color: #999;
  }
  
  @media (max-width: 767px) {
    #event .titleArea {
      padding: 0 10px; /* Add padding to ensure the title does not touch the edges on smaller screens */
    }
  
    #event .commonTittle {
      font-size: 28px; /* Adjust font size for smaller screens */
    }
  
    #event .subTitle {
      font-size: 10px; /* Adjust font size for smaller screens */
    }
  
    .event-card {
      height: auto; /* Adjust height for smaller screens */
    }
  }


/* DESIGNED CARDS */
.section_our_solution .row {
  align-items: center;
}

.our_solution_category {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.our_solution_category .solution_cards_box {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.solution_cards_box .solution_card {
  flex: 0 50%;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(136, 144, 195, 0.2),
    0 5px 15px 0 rgba(37, 44, 97, 0.15);
  border-radius: 15px;
  margin: 15px;
  padding: 10px 15px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 260px;
  transition: 0.7s;
}

.solution_cards_box .solution_card:hover {
  background: #95a55e;
  color: #fff;
  transform: scale(1.1);
  z-index: 9;
}

.solution_cards_box .solution_card:hover::before {
  background: rgb(85 108 214 / 10%);
}

.solution_cards_box .solution_card:hover .solu_title h3,
.solution_cards_box .solution_card:hover .solu_description p {
  color: #fff;
}

.solution_cards_box .solution_card:before {
  content: "";
  position: absolute;
  background: rgb(85 108 214 / 5%);
  width: 170px;
  height: 400px;
  z-index: -1;
  transform: rotate(42deg);
  right: -56px;
  top: -23px;
  border-radius: 35px;
}

.solution_cards_box .solution_card:hover .solu_description button {
  background: #FBC02D !important;
  color: black;
}

.solution_card .so_top_icon {
}

.solution_card .solu_title h3 {
  color: #212121;
  font-size: 1.3rem;
  margin-top: 13px;
  margin-bottom: 13px;
}

.solution_card .solu_description p {
  font-size: 15px;
  margin-bottom: 15px;
}

.solution_card .solu_description button {
  border: 0;
  border-radius: 15px;
  /* background: linear-gradient(
    140deg,
    #42c3ca 0%,
    #42c3ca 50%,
    #42c3cac7 75%
  ) !important; */
   background: #FBC02D;
  color: gray;
  font-weight: 500;
  font-size: 1rem;
  padding: 5px 16px;
}

.our_solution_content h1 {
  text-transform: capitalize;
  margin-bottom: 1rem;
  font-size: 2.5rem;
}
.our_solution_content p {
}

.hover_color_bubble {
  position: absolute;
  background: rgb(54 81 207 / 15%);
  width: 100rem;
  height: 100rem;
  left: 0;
  right: 0;
  z-index: -1;
  top: 16rem;
  border-radius: 50%;
  transform: rotate(-36deg);
  left: -18rem;
  transition: 0.7s;
}

.solution_cards_box .solution_card:hover .hover_color_bubble {
  top: 0rem;
}

.solution_cards_box .solution_card .so_top_icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.solution_cards_box .solution_card .so_top_icon img {
  width: 40px;
  height: 50px;
  object-fit: contain;
}

/*start media query*/
@media screen and (min-width: 320px) {
  .sol_card_top_3 {
    position: relative;
    top: 0;
  }

  .our_solution_category {
    width: 100%;
    margin: 0 auto;
  }

  .our_solution_category .solution_cards_box {
    flex: auto;
  }
}
@media only screen and (min-width: 768px) {
  .our_solution_category .solution_cards_box {
    flex: 1;
  }
}
@media only screen and (min-width: 1024px) {
  .sol_card_top_3 {
    position: relative;
    top: -3rem;
  }
  .our_solution_category {
    width: 100%;
    margin: 0 auto;
  }
}

  