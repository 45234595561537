/* Gallery.module.css */
.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 20px;
    padding: 40px;
  }
  
  .galleryItem {
    position: relative;
    overflow: hidden;
  }
  
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .image:hover {
    transform: scale(1.1);
  }
  