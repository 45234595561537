/* Modal.module.css */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    text-align: center;
    position: relative; /* Added to position the close button */
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease; /* Add transition for smooth effect */
}

.closeButton:hover {
  color: red; /* Change color on hover */
  transform: scale(1.2); /* Slightly enlarge the button on hover */
}
  
  .modalImage {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  