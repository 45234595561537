/* style.css */
#about {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    padding: 50px 0;
    background-color: #f9f9f9;
  }
  
  .gurus-section img {
    border-radius: 50%;
  }
  
  .heading-col {
    position: relative;
  }
  
  .historyContent {
    padding-left: 169px;
    position: relative;
    margin-top: 57px;
  }
  
  #about .titleArea {
    display: table;
    left: -32px;
    margin: auto;
    position: absolute;
    top: 90px;
    transform: rotate(-90deg);
    width: auto;
  }
  
  #about .commonTittle {
    color: #95a55e;
    font-size: 36px;
    letter-spacing: 10.8px;
    padding-bottom: 5px;
    text-transform: uppercase;
    font-weight: 700;
  }
  
  #about .subTitle {
    font-size: 12px;
    font-style: italic;
    text-transform: uppercase;
    letter-spacing: 3.6px;
    font-weight: 400;
    font-family: 'Merriweather', serif;
    padding-right: 12px;
    color: #999999;
  }
  
  #about .guru-section-title {
    padding-left: 20px;
    position: relative;
  }
  
  .guru-section-title:before {
    position: absolute;
    left: 0px;
    top: 5px;
    height: calc(100% - 8px);
    width: 4px;
    background: #95a55e;
    content: "";
  }
  
  .guru-title {
    text-transform: uppercase;
    color: #999999;
  }
  
  .about-guru {
    color: #263238;
    font-size: 0.9em;
  }
  
  .img-fluid {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  @media (max-width: 767px) {
    .historyContent {
      padding-left: 0;
      margin-top: 20px;
      text-align: center;
    }
  
    #about .titleArea {
      position: static;
      transform: none;
      margin-bottom: 20px;
    }
  
    .gurus-section {
      padding-top: 0;
    }
  
    .guru-section-title {
      text-align: left;
    }
  
    .guru-title,
    .about-guru {
      text-align: left;
    }
  }
  