/* @font-face {
	font-family: namaskar;
	src: url(../../components/Navbar/Samarkan-Normal1.ttf.woff);
} */

.footer {
    background-color: #263238;
    text-align: center;
    width: 100%;
    padding: 10px 0;
    color: rgba(255,255,255,0.5);
  }
  