/* style.css */
#sliderSection {
    /* height: 100vh; Make the slider take up the full viewport height */
    overflow: hidden;
    margin: 0; /* Remove any default margin */
    padding: 0; /* Remove any default padding */
  }
  
  #sliderSection img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .carousel-inner {
    height: 100%; /* Ensure the carousel inner takes up the full height */
  }
  
  .carousel-item {
    height: 100%; /* Ensure each carousel item takes up the full height */
  }
  
  .carousel-control-prev,
  .carousel-control-next {
    height: 100%; /* Ensure the controls take up the full height */
  }
  