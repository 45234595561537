.blog_section {
  padding: 40px 0;
}

.blog_content {
  max-width: 1200px;
  margin: 0 auto;
}

.columns {
  column-count: 4; /* Adjust the number of columns as needed */
  column-gap: 10px;
}

@media (max-width: 1100px) {
  .columns {
    column-count: 3;
  }
}

@media (max-width: 700px) {
  .columns {
    column-count: 2;
  }
}

@media (max-width: 500px) {
  .columns {
    column-count: 1;
  }
}

.gallery_item {
  break-inside: avoid;
  margin-bottom: 10px;
  cursor: pointer;
  transition: transform 0.2s;
}

.gallery_item:hover {
  transform: scale(1.02);
}

.gallery_image {
  width: 100%;
  height: auto;
  display: block;
}
